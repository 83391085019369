export const URL_COMMON = {
	LOCATION_SEARCH: "/api/common/location-search/",
  PROVINCE: "/api/common/province/",
  PERSONAL_CARD_EXTRACT: "/api/common/personal-card-extract/",
}

export const URL_AUTH = {
  API_TOKEN_AUTH: "/api/users/api-token-auth/",
  CHANGE_PASSWORD: "/api/users/change-password/",
  USER: "/api/users/user/",
  API_LIFF_TOKEN_AUTH: "/api/users/liff-token-auth/",
  CHECK_LIFF_AUTH: "/api/users/checking-liff-auth/",
  SIGN_OUT: "/api/users/sign-out/",
};

export const URL_MEMBER = {
  FARMER: "/api/member/farmer/",
  COLLECTING_CENTER: "/api/member/collecting-center/",
  USER_COLLECTING_CENTER: "/api/member/user-collecting-center/",
  FACTORY: "/api/member/factory/",
  FACTORY_LICENSE_DOCUMENT: "/api/member/factory-license-document/",
  FACTORY_CREATEABLE: "/api/member/can-create-factory/",
  FACTORY_MANAGE_TZ: "/api/member/factory-manage-tz/",
  OFFICER: "/api/member/officer/",
  CHECK_DUPLICATE_CITIZEN_ID: "/api/member/duplicate-citizen-id/",
};

export const URL_PLANTATIONS = {
  WORKER: "/api/plantation/worker/",
  PLANTATIONS: "/api/plantation/plantation/",
  DOCUMENT: "/api/plantation/plantation-document/",
  DEED_TYPE: "/api/plantation/deed-type/",
  RUBBER_TREE_TYPE: "/api/plantation/rubber-tree-type/",
  PEST_TYPE: "/api/plantation/pest-type/",
  AREA_LAYER: "/api/plantation/plantation-area-layer/",
  UPDATE_LOG: "/api/plantation/plantation-updatelog/",
  UPDATE_LOG_DOCUMENT: "/api/plantation/plantation-updatelog-document/",
  WOOD_TREE: "/api/plantation/woodtree/",
  ANIMAL_TYPE: "/api/plantation/animal/",
  ADDITIONAL_DOCUMENT: "/api/plantation/additional-document-attachment/",
  CHECK_PLANTATION_OVERLAP: "/api/plantation/check-plantation-overlap/",
  NEIGHBOR_PLOT: "/api/plantation/plantation-neighbor/",
}

export const URL_CUSTOMER = {
  CUSTOMER: "/api/customer/customer/",
  PRODUCT: "/api/customer/product/",
  VEHICLE: "/api/customer/vehicle/",
  DRIVER: "/api/customer/driver/",
  CASHIER: "/api/customer/cashier/",
  MASTER_PRODUCT: "/api/customer/master-product/",
  MASTER_PRODUCT_TYPE: "/api/customer/master-product-type/",
}

export const URL_WEIGHT = {
  WEIGHT_TICKET: "/api/weight/weight-ticket/",
  WEIGHT_TICKET_DRIVER_ATTACHMENT: "/api/weight/weight-ticket-driver-attachment/",
  WEIGHT_TICKET_INCOMING: "/api/weight/weight-ticket-incoming/",
  TICKET_FORCE_UPDATE: "/api/weight/ticket-force-update/",
  TICKET_UPDATELOG: "/api/weight/ticket-updatelog/",
}

export const URL_STOCK = {
  STOCK: "/api/stock/stock/",
  STOCK_TRANSACTION: "/api/stock/stock-transaction/",
}


export const URL_DELIVERY = {
  DELIVERY_TRANSACTION: "/api/delivery/delivery-transaction/",
  DELIVERY_OUTGOING: "/api/delivery/delivery-outgoing/",
  DELIVERY_CERTIFICATE: "/api/delivery/delivery-certificate/",
  SHIPPING_INSTRUCTION: "/api/delivery/shipping-instruction/",
  PUBLIC_SHIPPING_INSTRUCTION: "/api/delivery/public-shipping-instruction/",
  PLANTATIONS_SOURCE_SI_TRACKING: "/api/delivery/public-plantation-source-si-tracking/",   
  PLANTATIONS_SI_TRACKING: "/api/delivery/plantation-si-tracking/", 
  DRIVER_ATTACHMENT: "/api/delivery/driver-attachment/",                    
  PLANTATION_GEOJSON: "/api/delivery/si-plantation-geojson/",
  SI_CUSTOMER: "/api/delivery/si-customer/"
}

export const URL_REPORT = {
  FSC_CONSENT: "/api/report/fsc-consent-report/",
  PDPA_CONSENT: "/api/report/pdpa-consent-report/",
  DEL_REPORT: "/api/report/del-report/", // For CC
  DEL_FACTORY_REPORT: "/api/report/del-factory-report/", // For factory
  APP05: "/api/report/app05-report/",
  RUBBER05: "/api/report/rubber05-report/",
  RUBBER06: "/api/report/rubber06-report/",
  RUBBER07: "/api/report/rubber07-report/",
  RUBBER08: "/api/report/rubber08-report/",
  DELIVERY_BY_PRODUCT: "/api/report/delivery-by-product-report/",
  DELIVERY_BY_MONTH: "/api/report/delivery-by-month-report/",
  REPORT_OF_PLANTATION: "/api/report/report-of-plantation/",
  REPORT_OF_PLANTATION_GEOJSON: "/api/report/report-of-plantation-geojson/",
  M01: "/api/report/m01-report/",
  PLANTATION_SI: "/api/report/report-of-plantation/si/",
  GM_REPORT: "/api/report/gm-report/",
  DDR_REPORT: "/api/report/ddr-report/",
  PLANTATION_OVERLAP: "/api/report/plantation-overlap-report/",
  LAYER_OVERLAP: "/api/report/layer-overlap-report/",
  PLANTATION_SUMMARY_REPORT: "/api/report/plantation-summary-report"
}
export const URL_DASHBOARD = {
  OVERALL_PLANTATION: "/api/dashboard/overall-plantation/",
  OVERALL_PLANTATION_CC: "/api/dashboard/overall-plantation/cc/",
  STATUS_FOCUS:"/api/dashboard/status-focus/",
  STATUS_FOCUS_CC:"/api/dashboard/status-focus/cc/",
  PLANTATION_FOCUS:"/api/dashboard/plantation-focus/",
  PLANTATION_FOCUS_CC:"/api/dashboard/plantation-focus/cc/",
  FARMER_FOCUS:"/api/dashboard/farmer-focus/",
  FARMER_FOCUS_CC:"/api/dashboard/farmer-focus/cc/",
  STOCK_FOCUS:"/api/dashboard/stock-dashboard/",
  STOCK_FOCUS_CC:"/api/dashboard/stock-dashboard/cc/",
  DELIVERY_FOCUS_CC:"/api/dashboard/delivery-dashboard/cc/",
  DELIVERY_FOCUS:"/api/dashboard/delivery-dashboard/",
  DELIVERY_FACTORY_DASHBOARD: "/api/dashboard/delivery-factory-dashboard/",
  OVERALL_PLANTATION_FACTORY:"/api/dashboard/overall-plantation/factory/",
  STATUS_FOCUS_FACTORY:"/api/dashboard/status-focus/factory/",
  FARMER_FOCUS_FACTORY:"/api/dashboard/farmer-focus/factory/",
  PLANTATION_FOCUS_FACTORY:"/api/dashboard/plantation-focus/factory/",
  STOCK_CURRENT_DASHBOARD:"/api/dashboard/stock-current-dashboard/"
}

export const URL_GEOGRAPHY = {
  LAYER_DETAIL: "/api/geography/layer-detail/",
  CHECK_POLYGON_OVERLAP: "/api/geography/check-polygon-overlap/",
  CHEKC_POINT_OVERLAP: "/api/geography/check-point-overlap/",
}