import React from 'react';
import { Button, Card, Form, Col, Input, Row, Select, Spin, List, message, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { DELETE, GET } from '../../frameworks/HttpClient';
import { EditControl } from 'react-leaflet-draw';
import { MapContainer, TileLayer, FeatureGroup, Polygon, LayerGroup, LayersControl, Marker, Popup } from "react-leaflet";
import { URL_COMMON, URL_GEOGRAPHY, URL_PLANTATIONS } from '../../constants/urls';
import { 
  PLANTATION_POLYGON_TYPE, 
  POLYGON_TYPE_COLOR, 
  DEFAULT_LOCATION,
  GEOGRAPHY_LAYER_TYPES,
  GEOGRAPHY_LAYER_COLOR,
  GEOGRAPHY_LAYER_NAMES, 
  LANGUAGE} from '../../constants/string';
import { useRef } from 'react';
import L from 'leaflet'
import _ from "lodash";
import AddPolygonModal from '../common/AddPolygonModal';
import 'leaflet-geosearch/dist/geosearch.css';
import 'leaflet/dist/leaflet.css';
import "react-leaflet-draft";
import '../../assets/map.css';
import { useTranslation } from 'react-i18next';
import { PRIMARY_COLOR } from '../../constants/color';
import UploadFile from '../common/UploadFile';

const GEOGRAPHY_LAYER_KEY = "area"

const PlantationLocationView = React.forwardRef((props,ref) => {
  const mapRef = useRef();
  const [form] = Form.useForm();
  const selected_sub_district = Form.useWatch('sub_district', form)
  const documentRef = React.useRef(null);
  const [selectedProvince, setSelectedProvince] = React.useState(null);
  const [debouncedCode, setDebouncedCode] = React.useState(null);
  const [subdistricts, setSubdistricts] = React.useState();
  const [errorMessages , setErrorMessages] = React.useState(null);
  const [center, setCenter] = React.useState(DEFAULT_LOCATION);
  const [layerType, setLayerType] = React.useState('total');
  const [deleteMode, setDeleteMode] =  React.useState(false);
  const [loadTileMap, setLoadTileMap] =  React.useState(false);
  const [remove, setRemove] = React.useState(null);
  const [OpenAddPolygonModal, setOpenAddPolygonModal] = React.useState(false);
  const [polygons, setPolygons] = React.useState([]);
  const [searchPosition, setSearchPosition] = React.useState(null);
  const [deletedPolygon, setDeletedPolygon] = React.useState([]);
  const [inputLat, setInputLat] = React.useState('');
  const [inputLng, setInputLng] = React.useState('');
  const [marker, setMarker] = React.useState(null);
  const [point, setPoint] = React.useState(null);
  const [allPolygon, setAllPolygon] = React.useState(null);
  const [geoPolygon, setGeoPolygon] = React.useState(null);
  const [convertCoor, setConvertCoor] = React.useState([]);
  const [deForestLayer, setDeforestLayer] = React.useState([]);
  const [nationalParkLayer, setNationalParkLayer] = React.useState([]);
  const [nonHuntingLayer, setNonHuntingLayer] = React.useState([]);
  const [protectedAreaLayer, setProtectedAreaLayer] = React.useState([]);
  const [forestCoverLayer, setForestCoverLayer] = React.useState([]);
  const [reservedForestLayer, setReservedForestLayer] = React.useState([]);
  const [neighborPlotLayer, setNeighborPlotLayer] = React.useState([]);
  const [mainPolygon, setMainPolygon] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false)
  const { t, i18n } = useTranslation();

  const PLANTATION_POLYGON_TYPE_OPTIONS = [
    { key: PLANTATION_POLYGON_TYPE.TOTAL, value: PLANTATION_POLYGON_TYPE.TOTAL, label: t("area_according_to_rights_document") },
    { key: PLANTATION_POLYGON_TYPE.OPERATION, value: PLANTATION_POLYGON_TYPE.OPERATION, label: t("garden_area") },
    { key: PLANTATION_POLYGON_TYPE.WATER_SOURCE, value: PLANTATION_POLYGON_TYPE.WATER_SOURCE, label: t("water_source_area") },
    { key: PLANTATION_POLYGON_TYPE.CONSERVATION, value: PLANTATION_POLYGON_TYPE.CONSERVATION, label: t("conservation_area") },
    { key: PLANTATION_POLYGON_TYPE.RESIDENTAL, value: PLANTATION_POLYGON_TYPE.RESIDENTAL, label: t("residential_area") }
  ]

  const iconSearchMarker = new L.Icon({
    iconUrl: require('../../assets/search-marker.png'),
    iconRetinaUrl: require('../../assets/search-marker.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });

  const iconMarker = new L.Icon({
    iconUrl: require('../../assets/marker-1.png'),
    iconRetinaUrl: require('../../assets/marker-1.png'),
    iconAnchor: [13, 40],
    iconSize: [25, 40],
  });


  React.useImperativeHandle(ref, () => ({ 
    getData : handleRegister,
    getDeletedPolygon : () => deletedPolygon,
    resetMap : async() => {
        await setLoadTileMap(false)  
    }
  }))

  const colorPolygon = (item) => { 
    return{  
    color : item.type === PLANTATION_POLYGON_TYPE.TOTAL ? POLYGON_TYPE_COLOR.TOTAL :
    item.type === PLANTATION_POLYGON_TYPE.OPERATION ? POLYGON_TYPE_COLOR.OPERATION : 
    item.type === PLANTATION_POLYGON_TYPE.WATER_SOURCE ? POLYGON_TYPE_COLOR.WATER_SOURCE :
    item.type === PLANTATION_POLYGON_TYPE.CONSERVATION ? POLYGON_TYPE_COLOR.CONSERVATION :
    item.type === PLANTATION_POLYGON_TYPE.RESIDENTAL ? POLYGON_TYPE_COLOR.RESIDENTAL : ""
    }
  }

  const typeByColor = (color) => {
    return color === POLYGON_TYPE_COLOR.TOTAL ? PLANTATION_POLYGON_TYPE.TOTAL :
      color === POLYGON_TYPE_COLOR.OPERATION ? PLANTATION_POLYGON_TYPE.OPERATION :
      color === POLYGON_TYPE_COLOR.WATER_SOURCE ? PLANTATION_POLYGON_TYPE.WATER_SOURCE :
      color === POLYGON_TYPE_COLOR.CONSERVATION ? PLANTATION_POLYGON_TYPE.CONSERVATION :
      color === POLYGON_TYPE_COLOR.RESIDENTAL ? PLANTATION_POLYGON_TYPE.RESIDENTAL : null
  }

  const handleLatLngChange = (e) => {
    const { name, value } = e.target;
    if (name === 'lat') {
      setInputLat(value);
    } else if (name === 'lng') {
      setInputLng(value);
    }
  }

  const handleFlyTo = () => {
    const lat = parseFloat(inputLat);
    const lng = parseFloat(inputLng);

    if (!isNaN(lat) && !isNaN(lng)) {
      setSearchPosition([lat,lng])
      mapRef.current.flyTo([lat, lng], 20);
    } else {
      message.error(t("please_enter_Latitude_Longitude"));
    }
  };

  const debounceSubdistrict = React.useCallback(
    _.debounce((_searchVal) => { setDebouncedCode(_searchVal) }, 800),
    []
  );

  const typeColor = {
    color : layerType === PLANTATION_POLYGON_TYPE.TOTAL ? POLYGON_TYPE_COLOR.TOTAL :
    layerType === PLANTATION_POLYGON_TYPE.OPERATION ? POLYGON_TYPE_COLOR.OPERATION : 
    layerType === PLANTATION_POLYGON_TYPE.WATER_SOURCE ? POLYGON_TYPE_COLOR.WATER_SOURCE :
    layerType === PLANTATION_POLYGON_TYPE.CONSERVATION ? POLYGON_TYPE_COLOR.CONSERVATION :
    layerType === PLANTATION_POLYGON_TYPE.RESIDENTAL ? POLYGON_TYPE_COLOR.RESIDENTAL : ""
    ,clickable: false
  }

  const preparePolygon = async (points, Leaflet_id, color) => {
    const currentLayerType = typeByColor(color)
    const polyPoints = points.reduce((sd, p) => ([...sd, { latitude: p.lat, longitude: p.lng }]), [])
    const newPolygon = { id: Leaflet_id, type: currentLayerType, polygon: [...polyPoints, polyPoints[0]] }
    setPolygons(prevPolygons => ([...prevPolygons, { ...newPolygon }]))

    // Check overlap layer with backend
      await checkLayerOverlap([...points.reduce((sd, p) => ([...sd, [p.lng, p.lat]]), []), [points[0].lng, points[0].lat]])
  }

  const prepareMarker = (e) => {
    const data = { latitude : e.lat, longitude : e.lng }
    setMarker(data)
    setPoint(e)
    checkLayerOverlap(data)
  }

  const fetchSubdistrict = async () => {
		setIsLoading(true);
		try {
			const response = await GET(`${URL_COMMON.LOCATION_SEARCH}${debouncedCode}`)
			setSubdistricts(response.data.results.map((item) => {
				return {
					...item,
					label: i18n.language === LANGUAGE.TH ? item.display_name : item.display_name_en,
					value: item.subdistrict_id
				}
			}))
		} catch (error) {
			console.log(error.errorMessages);
		}
		finally {
      setIsLoading(false);
		}
	}

  const handleRegister = async () => {
    setErrorMessages(null)

    try {
      const data = await form.validateFields();
      data['point'] = marker;
      
      const subdistrict = data.sub_district;

      if (subdistrict) {
        data['sub_district'] = subdistrict.value
      } else {
        data['sub_district'] = null
      }

      if (documentRef.current) {
        const documentData = await documentRef.current.getData()
        data["additional_document"] = documentData ? documentData : [];
      }

      return {...data, polygon : polygons}

    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally { 
      form.resetFields();
      setPolygons([]);
    }
  }

  const checkLayerOverlap = async (data) => {
    setErrorMessages(null)
    setIsLoading(true)
    try {
      const urlPolygon = Array.isArray(data) ? URL_GEOGRAPHY.CHECK_POLYGON_OVERLAP : URL_GEOGRAPHY.CHEKC_POINT_OVERLAP
      const urlPlantation = Array.isArray(data) ? URL_PLANTATIONS.CHECK_PLANTATION_OVERLAP : null
      const param = Array.isArray(data) ? { polygon: JSON.stringify(data) } : { point: JSON.stringify(data)}
      const response = await Promise.all([
        GET(urlPolygon, param),
        urlPlantation ? GET(urlPlantation, {...param, exclude_id : props.data ? props.data.id : null}) : []
      ])
      if((response[0],response[0].data != null && response[0].data.length > 0) || (response[1],response[1].data != null && response[1].data.length > 0)) {
        Modal.warning({
          title: "Overlap Layer Detected",
          content: (
            <ul>
              {response[0] && response[0].data.map(layer => (
                <li>
                  {`${layer.name} (${GEOGRAPHY_LAYER_NAMES[
                    Object.keys(GEOGRAPHY_LAYER_TYPES).find(
                      key => GEOGRAPHY_LAYER_TYPES[key] === layer.type
                    )
                    ]
                    })`}
                </li>
              ))}
              {response[1] && response[1].data.map(layer => (
                <li>
                  {`${layer.code == null ? 'แปลงรอการอนุมัติ' : layer.code} (${layer.farmer_name})`}
                </li>
              ))}
            </ul>
          )
        })
      }
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  const onMapReady = (props) => {
    props.target.on('overlayadd', async ({name}) => {
      if (name.includes(GEOGRAPHY_LAYER_NAMES.DE_FOREST)) {
        await fetchGeographicLayer(
          GEOGRAPHY_LAYER_TYPES.DE_FOREST, 
          { province: selectedProvince }, 
          setDeforestLayer
        )
      }
      else if(name.includes(GEOGRAPHY_LAYER_NAMES.NATIONAL_PARK)) {
        await fetchGeographicLayer(
          GEOGRAPHY_LAYER_TYPES.NATIONAL_PARK, 
          {},
          setNationalParkLayer
        )
      }
      else if(name.includes(GEOGRAPHY_LAYER_NAMES.NON_HUNTING)) {
        await fetchGeographicLayer(
          GEOGRAPHY_LAYER_TYPES.NON_HUNTING, 
          { province: selectedProvince },
          setNonHuntingLayer
        )
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.PROTECTED_AREA)) {
        await fetchGeographicLayer(
          GEOGRAPHY_LAYER_TYPES.PROTECTED_AREA,
          { province: selectedProvince },
          setProtectedAreaLayer
        )
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.FOREST_COVER)) {
        await fetchGeographicLayer(
          GEOGRAPHY_LAYER_TYPES.FOREST_COVER,
          { province: selectedProvince },
          setForestCoverLayer
        )
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.RESERVED_FOREST)) {
        await fetchGeographicLayer(
          GEOGRAPHY_LAYER_TYPES.RESERVED_FOREST,
          { province: selectedProvince },
          setReservedForestLayer
        )
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.NEIGHBOR_PLOT)) {
        await fetchNeighborPlotLayer(setNeighborPlotLayer)
      }
    })
    

    props.target.on('overlayremove', ({name}) => {
      if(name.includes(GEOGRAPHY_LAYER_NAMES.DE_FOREST)) {
        setDeforestLayer([])
      }
      else if(name.includes(GEOGRAPHY_LAYER_NAMES.NATIONAL_PARK)) {
        setNationalParkLayer([])
      }
      else if(name.includes(GEOGRAPHY_LAYER_NAMES.NON_HUNTING)) {
        setNonHuntingLayer([])
      }
      else if(name.includes(GEOGRAPHY_LAYER_NAMES.PROTECTED_AREA)) {
        setProtectedAreaLayer([])
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.FOREST_COVER)) {
        setForestCoverLayer([])
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.RESERVED_FOREST)) {
        setReservedForestLayer([])
      }
      else if (name.includes(GEOGRAPHY_LAYER_NAMES.NEIGHBOR_PLOT)) {
        setNeighborPlotLayer([])
      }
    })
  }

  const convertCoordinates = (coordinates) => {
    return coordinates.map(coord => [coord[1], coord[0]]);
  };


  const handleDeleteButton = () => {
    if(deleteMode === false){
      setDeleteMode(true)
    }else {
      setDeleteMode(false)
    }
  }

  const handleAllLatLngPolygon= async() =>{
    let allGeoPolygon = [...props.polygonLayer]
    let data = [];
    let mainPolygon = [];
    try {
      allGeoPolygon.map((latlng) => {
        data = [...data,latlng.geojson.coordinates]
        mainPolygon = [...mainPolygon, [latlng.centroid.longitude, latlng.centroid.latitude]]
      })
      setAllPolygon(JSON.stringify(data))
      setMainPolygon(mainPolygon)
    } catch (error) {
      console.log(error.errorMessages);
    }
  }

  const fetchGeographicLayer = async (layerType, params, callbackFunc) => {
    try {
      setIsLoading(true)
      const response = await GET(
        `${URL_GEOGRAPHY.LAYER_DETAIL}${layerType}/`,
        params)

      callbackFunc (
        response.data.results.flatMap(layer => {
          const coodinates = JSON.parse(layer.polygons)['coordinates']
          return coodinates.map(cood =>
          (
            {
              [GEOGRAPHY_LAYER_KEY]: cood.map(data => data.map(([lat, long]) => {
                return [long, lat]  // convert backend lib to frontend long, lat
              }))
            }
          )
          )
        })
      )
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally { setIsLoading(false) }
  }

  const fetchNeighborPlotLayer = async (callbackFunc) => {
    try {
      setIsLoading(true)
      if(mainPolygon.length > 0){
        const mainPlot = mainPolygon
        let data = []
          mainPlot.map(async(plot,) => {
            const response = await GET(
              `${URL_PLANTATIONS.NEIGHBOR_PLOT}`,
              { 
                radius: 2.5,
                longitude: plot[0],
                latitude: plot[1],
                page_size : 999
              }
            )
            data = [...data, ...response.data.results]
            data = data.filter(item => item.id !== props.data.id)
            callbackFunc(_.uniqBy(data, 'id'))
          })
      } else {
        const response = await GET(
          `${URL_PLANTATIONS.NEIGHBOR_PLOT}`,
          { 
            radius: 5,
            longitude: center[1],
            latitude: center[0],
            page_size : 999
          }
        )
        callbackFunc(response.data.results)
      }
    } catch (error) {
      console.log(error.errorMessages);
    } finally { 
      setIsLoading(false)
    }
  }

  const renderLayer = (color, item) => (
    <LayerGroup>
      <Polygon color={color} positions={item[GEOGRAPHY_LAYER_KEY]} />
    </LayerGroup>
  )

  const renderLayerNeighbor = (color, item, data) => {
    return(
    <LayerGroup>
      <Polygon color={color} positions={item[0].reduce(
          (points, latlng) => (
            [...points, [latlng[1], latlng[0]]]
          ), []
        )} >
          <Popup>{`[${data.code ? data.code : 'แปลงรอการอนุมัติ' }]`}<br />{_.get(data, 'farmer_name', 'ไม่ทราบ')},</Popup>
      </Polygon>
    </LayerGroup>
    )
  }

  const renderPolygon = (item) => {
    return(
    <LayerGroup>
      <Polygon
        value={item.id}
        pathOptions={colorPolygon(item)}
        positions={item.polygon.reduce(
          (points, latlng) => (
            [...points, [latlng.latitude, latlng.longitude]]
          ), []
        )}
        eventHandlers={{
          click: (e) => {
            //if (layerType === item.type && deleteMode === true) {
            if (deleteMode === true) {
              mapRef.current.removeLayer(e.target);
              setRemove(e)
              setDeleteMode(false);
              setDeletedPolygon(prevState => ([...prevState, e.target.options.value]))
            }
          },
        }}>
      </Polygon>
    </LayerGroup>
  )}

  React.useEffect(() => {
		if (debouncedCode)
			fetchSubdistrict();
	}, [debouncedCode])

  React.useEffect(() => {
    if(props.data){
      const data = props.data
      form.setFieldValue('sub_district', data.sub_district ? {label : i18n.language === LANGUAGE.TH ?  data.sub_district_detail : data.sub_district_detail_en, value : data.sub_district} : null)
    }
  },[i18n.language])

  React.useEffect(() => {
    try {
      if(geoPolygon){
        const dataList = (geoPolygon.map(convertCoordinates))
        setConvertCoor([...convertCoor, ...dataList])
      }
    } catch (error) {
      message.error('พิกัด GeoJSON ไม่ถูกต้อง')
    }
  },[geoPolygon])

  React.useEffect(() => {
    if(convertCoor.length > 0) {
      mapRef.current.flyTo([convertCoor[0][0][0], convertCoor[0][0][1]], 16);
    }
  }, [convertCoor])

  React.useEffect(() => {
    
    if(props.data){
      const data = props.data
      form.setFieldsValue(data)
      form.setFieldValue('sub_district', {label : i18n.language === LANGUAGE.TH ?  data.sub_district_detail : data.sub_district_detail_en, value : data.sub_district})

      if(props.data.point){
        setPoint([props.data.point.latitude, props.data.point.longitude])
        setMarker(props.data.point)
        setCenter([props.data.point.latitude, props.data.point.longitude])
      }
      
    }
      setLoadTileMap(true);
  },[props.data])

    React.useEffect(() => {
      if(remove){
        setPolygons(polygons.filter(item => item.id !== (remove.target.options.value)))
        setRemove(null)
      }
    },[remove])

    React.useEffect(() => {
      if (props.polygonLayer[0] && props.polygonLayer[0].centroid) {
        setCenter([props.polygonLayer[0].centroid.latitude,props.polygonLayer[0].centroid.longitude])
      } else {
        setCenter(DEFAULT_LOCATION)
      }
      setLoadTileMap(true)
      handleAllLatLngPolygon();
    },[props.polygonLayer, polygons])

    React.useEffect(() => {
      if (selected_sub_district && selected_sub_district.value) {  // Fetch province
        GET(URL_COMMON.PROVINCE, { 'sub_district': selected_sub_district.value}).then(resp => {
          if(resp.data.length > 0) {
            setSelectedProvince(state => `${resp.data[0].id}`)
          }
        })
      }
    }, [selected_sub_district])

  return (
    <Card>
      <Form
        form={form}
        layout="vertical"
        autoComplete="off">
      
      <Row gutter={16}>
      <Col md={14} sm={24} xs={24}>
        <Form.Item  
          name={"address"}
          label={t("the_location_of_the_rubber_plantation_village_number")}>
          <Input autoComplete="off"/>
        </Form.Item>
      </Col>

      <Col md={10} sm={24} xs={24}>
        <Form.Item  
          name={"sub_district"}
          label={t("farmers.subdistrict_district_province_zip_code")}>
          <Select 
            autoComplete="off"
            showSearch
            labelInValue
            filterOption={false}
            notFoundContent={isLoading ? <Spin size="small" /> : "-"}
            onSearch={debounceSubdistrict}
            options={subdistricts}
            allowClear={"-"}/>
        </Form.Item>
    
      </Col>
          <Col md={14} sm={24} xs={24}>
        <Form.Item
          name = 'note'
          label = {t("description_of_the_area")}>
            <TextArea autoComplete='off' placeholder={t("description")} rows={3}/>
        </Form.Item>
        </Col>
          <Col md={10} sm={24} xs={24}>
          <Form.Item 
            name="additional_document"
            label={t("additional_document")}>
              <UploadFile
                className={"upload-list-inline"}
                style={{ background: PRIMARY_COLOR, color: 'white' }}
                btnText={t('additional_document_upload')}
                maxCount={1}
                ref={documentRef}
                dataList={props.additional_document ? props.additional_document : null}
                onRemove={(data) => DELETE(`${URL_PLANTATIONS.DOCUMENT}${data.id}/`)}
              />
          </Form.Item>
        </Col>
      </Row>

      {selectedProvince &&

      <Card style={{marginBottom : 16}} >
      <Row>
      <Col md={5} sm={24} xs={24} style={{marginRight : 10}} >
        <Input
          type="text"
          placeholder="latitude"
          name="lat"
          value={inputLat}
          onChange={handleLatLngChange}
          addonBefore="lat"
        />
      </Col>
      <Col md={5} sm={24} xs={24} style={{marginRight : 10}} >
        <Input
          type="text"
          placeholder="longitude"
          name="lng"
          value={inputLng}
          onChange={handleLatLngChange}
          addonBefore="lng"
        />
      </Col>
      <Col md={2} sm={24} xs={24}>
        <Button onClick={handleFlyTo}>{t("search")}</Button>
      </Col>
      
      {/* Dropdown Polygon Type */}
      <Col md={6} sm={24} xs={24}>
        <Select
          autoComplete="off"
          placeholder={t("type")}
          defaultValue={'total'}
          options={PLANTATION_POLYGON_TYPE_OPTIONS}
          style={{ width : "100%"}}
          
          onChange={e => {
            setLayerType(e)
            setDeleteMode(false)
          }}
        />
      </Col>

      <Button danger type={deleteMode ? "dashed" :  "primary" } onClick={() => handleDeleteButton()} style={{marginLeft : 10}}>{deleteMode ? t("cancel") : t("actions.delete_data")}</Button>
      <Col md={3} sm={24} xs={24}>
        <Button type="primary" style={{marginLeft : 10}} onClick={() => setOpenAddPolygonModal(true)}>{t("polygon_coordinates")}</Button>
      </Col>
      </Row>

      <Spin spinning={isLoading}>
        <div style={{height: 600, marginTop: 16,}}>
          {loadTileMap === true &&
            <MapContainer whenReady={onMapReady} zoom={props.polygonLayer.length > 0 ? 17 : 9} maxZoom={20} center={center} ref={mapRef} attributionControl={false} >
              {/* <SearchField /> */}
              <LayersControl>
                  <LayersControl.BaseLayer  name="Google Map">
                    <TileLayer
                      maxZoom={20}
                      attribution="Google Maps"
                      url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                    />
                  </LayersControl.BaseLayer>

                  <LayersControl.BaseLayer checked name="Google Map Satellite">
                    <LayerGroup>
                      <TileLayer
                        maxZoom={20}
                        attribution="Google Maps Satellite"
                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                      />
                      <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}"/>
                    </LayerGroup>
                  </LayersControl.BaseLayer>
                  <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.DE_FOREST}">${GEOGRAPHY_LAYER_NAMES.DE_FOREST}</span>`}>
                    <LayerGroup>
                      <List
                        dataSource={deForestLayer}
                        renderItem={item => renderLayer(GEOGRAPHY_LAYER_COLOR.DE_FOREST, item)}
                      />
                    </LayerGroup>
                  </LayersControl.Overlay>
                  <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.NATIONAL_PARK}">${GEOGRAPHY_LAYER_NAMES.NATIONAL_PARK}</span>`}>
                  <LayerGroup>
                    <List
                      dataSource={nationalParkLayer}
                      renderItem={item => renderLayer(GEOGRAPHY_LAYER_COLOR.NATIONAL_PARK, item)}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.NON_HUNTING}">${GEOGRAPHY_LAYER_NAMES.NON_HUNTING}</span>`}>
                  <LayerGroup>
                    <List
                      dataSource={nonHuntingLayer}
                      renderItem={item => renderLayer(GEOGRAPHY_LAYER_COLOR.NON_HUNTING, item)}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.PROTECTED_AREA}">${GEOGRAPHY_LAYER_NAMES.PROTECTED_AREA}</span>`}>
                  <LayerGroup>
                    <List
                      dataSource={protectedAreaLayer}
                      renderItem={item => renderLayer(GEOGRAPHY_LAYER_COLOR.PROTECTED_AREA, item)}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.FOREST_COVER}">${GEOGRAPHY_LAYER_NAMES.FOREST_COVER}</span>`}>
                  <LayerGroup>
                    <List
                      dataSource={forestCoverLayer}
                      renderItem={item => renderLayer(GEOGRAPHY_LAYER_COLOR.FOREST_COVER, item)}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.RESERVED_FOREST}">${GEOGRAPHY_LAYER_NAMES.RESERVED_FOREST}</span>`}>
                  <LayerGroup>
                    <List
                      dataSource={reservedForestLayer}
                      renderItem={item => renderLayer(GEOGRAPHY_LAYER_COLOR.RESERVED_FOREST, item)}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay name={`<span style="color:${GEOGRAPHY_LAYER_COLOR.NEIGHBOR_PLOT}">${GEOGRAPHY_LAYER_NAMES.NEIGHBOR_PLOT}</span>`}>
                  <LayerGroup>
                    <List
                      dataSource={neighborPlotLayer}
                      renderItem={item => item.polygons.map((e) => renderLayerNeighbor(GEOGRAPHY_LAYER_COLOR.NEIGHBOR_PLOT, e ,item))}
                    />
                  </LayerGroup>
                </LayersControl.Overlay>
              </LayersControl>

              <FeatureGroup>
                  <EditControl
                    onCreated = {(e) => {
                      if(e.layerType === "polygon"){
                        preparePolygon(
                          e.layer._latlngs[0], 
                          e.layer._leaflet_id,
                          e.layer.options.color
                        )
                        mapRef.current.removeLayer(e.layer)
                      }
                      if(e.layerType === "marker"){
                        prepareMarker(e.layer._latlng)
                        mapRef.current.removeLayer(e.layer)
                      }
                    }}

                    onDeleted = {(e) => {
                      let deletedLayers = e.layers._layers
                      let totalDelete = []
                        for (var layer in deletedLayers) {
                            totalDelete = [...totalDelete,deletedLayers[layer]]
                      }
                      setDeleteMode(false);
                    }}

                    draw={{
                      polygon: {
                        shapeOptions: { ...typeColor },
                      },
                      marker: { icon: iconMarker },
                      polyline: false,
                      rectangle: false,
                      circle: false,
                      circlemarker: false
                    }}

                    edit = {{
                      edit: false,
                      remove: false
                    }}
                  />

                  {/* Render fetch polygon type total */}
                  <List
                    dataSource={props.polygonLayer}
                    renderItem={renderPolygon}
                  />

                  {convertCoor.length > 0 &&
                    <Polygon 
                      pathOptions={{
                        color : POLYGON_TYPE_COLOR.TOTAL 
                      }} 
                      positions={convertCoor}
                      eventHandlers={{
                        add: (e) => {
                          preparePolygon(
                            e.target._latlngs[0], 
                            e.target._leaflet_id,
                            POLYGON_TYPE_COLOR.TOTAL
                          )
                          mapRef.current.removeLayer(e.target)
                        },
                        click: (e) => {
                          if(deleteMode === true ){
                            mapRef.current.removeLayer(e.target);
                            setDeleteMode(false);
                            setDeletedPolygon(prevState => ([...prevState, e.target.options.value]))
                          }
                        }
                      }}>
                    </Polygon>
                  }

                  {/* Render create polygon */}
                  <List
                    dataSource={polygons}
                    renderItem={renderPolygon}
                  />
              </FeatureGroup>
              {searchPosition && 
                <Marker 
                  position={searchPosition} 
                  icon={ iconSearchMarker }  
                  eventHandlers={{
                  click: (e) => {
                    if(deleteMode === true ){
                      mapRef.current.removeLayer(e.target);
                      setDeleteMode(false);
                      setSearchPosition(null);
                    }
                  }
                }}
                />
              }

              {point && 
                <Marker 
                  position={ point }
                  icon={ iconMarker }
                  eventHandlers={{
                    click: (e) => {
                      if(deleteMode === true ){
                        mapRef.current.removeLayer(e.target);
                        setPoint(null);
                        setMarker(null);
                        setDeleteMode(false);
                      }
                    }
                  }}
              />}

            </MapContainer>}
        </div>
      </Spin>
      </Card>
      }
    
      </Form>

      <AddPolygonModal
        open={OpenAddPolygonModal}
        onClose={() => 
          setOpenAddPolygonModal(false)
        }
        createPolygon={(latlng) => 
            setGeoPolygon(latlng)  
        }
        data={allPolygon}
      />
    </Card>
    
    )
  }
)

PlantationLocationView.defaultProps = {}

PlantationLocationView.propTypes = {}

export default React.memo(PlantationLocationView);